import fundingMethodBank from "../assets/images/funding-methods/fundingMethodBank.gif";
import fundingMethodCrypto from "../assets/images/funding-methods/fundingMethodCrypto.gif";
import fundingMethodIdeal from "../assets/images/funding-methods/fundingMethodIdeal.gif";
import fundingMethodNeteller from "../assets/images/funding-methods/fundingMethodNeteller.gif";
import fundingMethodSkrill from "../assets/images/funding-methods/fundingMethodSkrill.gif";
import fundingMethodSticpay from "../assets/images/funding-methods/fundingMethodSticpay.gif";
import fundingMethodVisaMC from "../assets/images/funding-methods/fundingMethodVisaMC.gif";
import fundingMethodAstropay from "../assets/images/funding-methods/fundingMethodAstroPay.png";
import fundingMethodMPesa from "../assets/images/funding-methods/fundingMethodMPesa.gif";

export const APP_URL = "https://stagesecure.iquoto.global";
// export const API_URL = "https://dev.iquoto.com:5001";
export const API_URL = "https://stagesecure.iquoto.global:5002";

export const encryptionKey = "SigurnoNeZnas!";

export const fundingMinimums = {
  minDepositAmount: 10,
  minWithdrawalAmount: 10,
  minInternalTransferAmount: 10,
  excludedEmails: [
    "miroslav.vegh@gmail.com",
    "raski19+12@gmail.com",
    "raski19+13@gmail.com",
    "dev@iquoto.com",
  ],
};

export const allowedPaymentMethodsPerEmail = {
  credit_card: [
    "raski19+12@gmail.com",
    "raski19+13@gmail.com",
    "miroslav.vegh@gmail.com",
    "dev@iquoto.com",
  ],
  crypto: [
    "raski19+12@gmail.com",
    "raski19+13@gmail.com",
    "miroslav.vegh@gmail.com",
    "dev@iquoto.com",
  ],
  mpesa: [
    "raski19+12@gmail.com",
    "raski19+13@gmail.com",
    "miroslav.vegh@gmail.com",
    "dev@iquoto.com",
  ],
};

// Crypto NACE
export const CRYPTO_NACE = {
  // widgetId: "122d0b42-6c69-413f-a2ad-daaad5c4f828", // DEV
  widgetId: "204f4e45-405a-4ef9-adc2-f04cf4af273f", // LIVE
  scriptUrl: "https://apps.newagecrypto.com/widget/nacepay.js?v4", // LIVE
  feePercentage: 0.8,
  coins: ["BTC", "ETH", "BCH", "LTC", "USDC", "USDT", "USDT_TRON"],
};
export const CRYPTO_WITHDRAWAL_DISCLAIMER =
  "<strong>Important!</strong> Be sure to enter valid crypto address. iQuoto is not responsible for withdrawals sent to wrong address or wrong network. Please be sure to check everything before initiating withdrawal.";

export const paymentMethodData = [
  {
    id: "credit_card",
    paymentGateway: "SC",
    method: "Credit/Debit Card",
    type: "cashier",
    codeName: "cc_card",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVisaMC,
    text: "Fund using VISA or Mastercard Credit or Debit Cards"
  },
//  {
//    id: "wire",
//    paymentGateway: "WIRE",
//    method: "Bank Transfer",
//    type: "wire",
//    time: "2-5 Days",
//    fees: "0%",
//    image: fundingMethodBank,
//    text:
//      "Fund using traditional bank transfer to our secure segregated bank account"
//  },
//  {
//    id: "skrill",
//    paymentGateway: "PS",
//    method: "Skrill",
//    type: "cashier",
//    codeName: "apmgw_MoneyBookers",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodSkrill,
//    text: "Fund instantly with your Skrill account"
//  },
//  {
//    id: "neteller",
//    paymentGateway: "PS",
//    method: "Neteller",
//    codeName: "apmgw_Neteller",
//    type: "cashier",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodNeteller,
//    text: "Fund instantly with your Neteller account"
//  },
//  {
//    id: "astropay",
//    method: "AstroPay",
//    type: "cashier",
//    codeName: "apmgw_AstropayPrePaid",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodAstropay,
//    text: "Fund instantly with your AstroPay account"
//  },
  {
    id: "ideal",
    paymentGateway: "SC",
    method: "iDEAL",
    type: "cashier",
    codeName: "apmgw_iDeal",
    time: "Instant",
    fees: "0%",
    image: fundingMethodIdeal,
    text: "Fund instantly with your iDEAL account"
  },
//  {
//    id: "mpesa",
//    paymentGateway: "ZP",
//    method: "M-Pesa",
//    type: "zotapay",
//    codeName: "",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodMPesa,
//    text: "Fund instantly with your M-Pesa account",
//  },
  // {
  //   id: "sticpay",
  //   method: "STICPAY",
  //   type: "sticpay",
  //   time: "Instant",
  //   fees: "0%",
  //   image: fundingMethodSticpay,
  //   text: "Fund instantly with your STICPAY account"
  // },
  // {
  //   id: "crypto",
  //   method: "Crypto Payment",
  //   type: "cashier",
  //   time: "1-2 Hours",
  //   fees: "0%",
  //   image: fundingMethodCrypto,
  //   text: "Fund safely and securely using Bitcoin, Ethereum, Ripple or Litecoin"
  // },
];

export const withdrawalMethodData = [
  {
    id: "credit_card",
    paymentGateway: "SC",
    method: "Credit/Debit Card",
    type: "cashier",
    codeName: "cc_card",
    time: "3-5 Days",
    fees: "0%",
    image: fundingMethodVisaMC
  },
  {
    id: "wire",
    paymentGateway: "WIRE",
    method: "Bank Transfer",
    type: "wire",
    time: "2-3 Days",
    fees: "0%",
    image: fundingMethodBank
  },
//  {
//    id: "mpesa",
//    paymentGateway: "ZP",
//    method: "M-Pesa",
//    type: "zotapay",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodMPesa,
//  },
//  {
//    id: "skrill",
//    method: "Skrill",
//    type: "cashier",
//    codeName: "apmgw_MoneyBookers",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodSkrill
//  },
//  {
//    id: "neteller",
//    method: "Neteller",
//    type: "cashier",
//    codeName: "apmgw_Neteller",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodNeteller
//  },
//  {
//    id: "astropay",
//    method: "AstroPay",
//    type: "cashier",
//    codeName: "apmgw_AstropayPrePaid",
//    time: "Instant",
//    fees: "0%",
//    image: fundingMethodAstropay
//  }
  // {
  //   id: "ideal",
  //   method: "iDEAL",
  //   type: "cashier",
  //   time: "Instant",
  //   fees: "0%",
  //   image: fundingMethodIdeal
  // },
  // {
  //   id: "crypto",
  //   method: "Crypto Payment",
  //   type: "cashier",
  //   time: "1-2 Hours",
  //   fees: "0%",
  //   image: fundingMethodCrypto
  // },
  // {
  //   id: "sticpay",
  //   method: "STICPAY",
  //   type: "cashier",
  //   time: "Instant",
  //   fees: "0%",
  //   image: fundingMethodSticpay
  // }
];

// SafeCharge API
export const SAFECHARGE = {
  paymentUrl: "https://secure.safecharge.com/ppp",
  // paymentUrl: "https://ppp-test.safecharge.com/ppp",
  merchantId: "373073551095279886",
  merchantSiteId: "192448",
  secretKey: "p427utTs5rlU4yNEEj6iyGUk3Mh2UwzjZgsb5AOVvYT57T2UvUtkqnHTUi6BlKM6"
};

// SumSub API
export const SUMSUB = {
  // apiUrl: "https://test-api.sumsub.com"
  apiUrl: "https://api.sumsub.com"
};

